import React from 'react';

import Layout from '../../components/layout';
import withBg from '../../helpers/withBg';
import SEO from '../../components/seo';
import TrustpilotHorizontal from '../../components/TrustpilotHorizontal/index';

const data = {
  title: 'Corporate Travel',
  seoTitle: 'Corporate Travel | Travelopod',
  seoDescription:
    'Find and book the cheapest flights to any destination at Travelopod! Save money on unique deals for flights, hotels and car rentals online or call us 24/7 for live booking assistance.'
};

class CorporateTravel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: props.phoneNumber
    };
  }

  render() {
    const { phoneNumber } = this.state;
    const description = data.seoDescription;
    const seotitle = data.seoTitle;

    return (
      <Layout
        isStaticPage
        passPhoneNumber={phoneNumber => {
          this.setState({
            phoneNumber
          });
        }}
      >
        <SEO title={seotitle} description={description} />
        <div className="main c-content-page">
          <div className="page-content full-width-page">
            <div className="text-block">
              <h1>Corporate Travel</h1>
              <p>
                At Travelopod we strive to offer custom designed solutions in
                most professional and cost effective manner. We assure you the
                best attention by the most knowledgeable people in the business
                coupled with ability to find the lowest fares. Overall we bring
                many years of experience mastering the complexities of arranging
                business travel so that you can benefit from our expertise.
              </p>
              <p>
                Dedicated travel managers are assigned for each of our clients
                who are well-experienced and use our powerful technology at the
                back-end to make travel procurement easy and hassle-free. Our
                ability to work with the most efficient processes and
                cost-effective travel planning help maximize ROI for your
                business.
              </p>
              <h2>Features of our Corporate Travel Services</h2>
              <ul>
                <li>24-hour emergency assistance by phone and chat</li>
                <li>Prompt ticketing and e-mail delivery</li>
                <li>Dedicated relationship manager</li>
                <li>Flexible payment options</li>
                <li>Cost Control and best ROI on business</li>
                <li>
                  Providing reports to monitor travel spending and track trends
                </li>
              </ul>
              <p>
                Many of our clients have already leveraged our services to
                manage their travel requirements with great testimonials. If
                your company is not using Travelopod.com consultants, we
                encourage you to try us.
              </p>
              <p>
                For further information, please email us at{' '}
                <a href="mailto:hello@travelopod.com">Hello@travelopod.com</a>.
                A travel expert will contact you within 24 hours.
              </p>
            </div>
          </div>
        </div>
        <TrustpilotHorizontal />
      </Layout>
    );
  }
}

export default withBg(CorporateTravel);
